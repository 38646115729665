// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-curious-js": () => import("./../../../src/pages/curious.js" /* webpackChunkName: "component---src-pages-curious-js" */),
  "component---src-pages-get-it-done-js": () => import("./../../../src/pages/get-it-done.js" /* webpackChunkName: "component---src-pages-get-it-done-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-panels-js": () => import("./../../../src/pages/panels.js" /* webpackChunkName: "component---src-pages-panels-js" */),
  "component---src-pages-take-it-easy-js": () => import("./../../../src/pages/take-it-easy.js" /* webpackChunkName: "component---src-pages-take-it-easy-js" */)
}

