import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sceneNumber: 0,
  loading: true,
  muted: false
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    initialLoad: (state) => {
      return { ...state, sceneNumber: 0, loading: false };
    },
    mute: (state) => {
      state.muted = true;
      return;
    },
    moveForward: (state) => {
      state.sceneNumber++;
      state.loading = true;
      return;
    },
    moveBack: (state, action) => {
      state.sceneNumber > 0 && state.sceneNumber--;
      state.loading = state.sceneNumber > 0 ? true : false;
      return;
    },
    loaded: (state, action) => {
      return { ...state, loading: false };
    },
  },
});

// Action creators are generated for each case reducer function
export const { initialLoad, moveForward, moveBack, loaded, mute } = globalSlice.actions;

export default globalSlice.reducer;
